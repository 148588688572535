import { Grid } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IcontainerProps {
  bgColor: string;
  children: ReactNode;
}

export const GridContainer = ({ bgColor, children }: IcontainerProps) => {
  return (
    <Grid
      pl="6"
      pr="6"
      w="100%"
      as="section"
      bg={bgColor}
      pt={["10", "10", "10", "0"]}
      pb={["10", "10", "10", "0"]}
      minH={["auto", "auto", "auto", "100vh"]}
    >
      {children}
    </Grid>
  );
};
