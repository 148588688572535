import imgMike from "../../assets/images/people/mike.svg";
import imgRochele from "../../assets/images/people/rochele.svg";
import imgRicardo from "../../assets/images/people/ricardo.svg";

export const carouselInfo = [
  {
    id: 1,
    title: "Fernanda Vieira",
    subtitle: "Cliente há 04 meses",
    text: "Minha loja veio incrivel, gostei muito da qualidado das minha imagens e principalmente do curso",
    image: {
      src: imgRochele,
      alt: "Imagem de perfil de uma mulher",
    },
  },
  {
    id: 2,
    title: "Jeferson Ribeiro",
    subtitle: "Cliente há 03 meses",
    text: "Confesso que eu não iria entrar porque sempre achei dificil esse mercado, mas vocês me mostraram um caminho que tirou isso da minha cabeça",
    image: {
      src: imgRicardo,
      alt: "Imagem de perfil de um homem",
    },
  },
  {
    id: 3,
    title: "Cleber Neves",
    subtitle: "Iniciante no Dropshipping",
    text: "No começo fiquei indeciso, depois consegui notar que a aparência da loja é muito importante para aumentar a venda, usando o tema Ella aumentei o meu faturamento",
    image: {
      src: imgMike,
      alt: "Imagem de perfil um homem",
    },
  },
  {
    id: 4,
    title: "Fernanda Vieira",
    subtitle: "Cliente há 04 meses",
    text: "Minha loja veio incrivel, gostei muito da qualidado das minha imagens e principalmente do curso",
    image: {
      src: imgRochele,
      alt: "Imagem de perfil de uma mulher",
    },
  },
  {
    id: 5,
    title: "Jeferson Ribeiro",
    subtitle: "Cliente há 03 meses",
    text: "Confesso que eu não iria entrar porque sempre achei dificil esse mercado, mas vocês me mostraram um caminho que tirou isso da minha cabeça",
    image: {
      src: imgRicardo,
      alt: "Imagem de perfil de um homem",
    },
  },
  {
    id: 6,
    title: "Cleber Neves",
    subtitle: "Iniciante no Dropshipping",
    text: "No começo fiquei indeciso, depois consegui notar que a aparência da loja é muito importante para aumentar a venda, usando o tema Ella aumentei o meu faturamento",
    image: {
      src: imgMike,
      alt: "Imagem de perfil um homem",
    },
  },
];
