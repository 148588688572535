import { theme } from "../../styles";
import { AiFillStar } from "react-icons/ai";
import { Text, Flex, Grid, Image, Center, Heading } from "@chakra-ui/react";

interface IproductProps {
  src: string;
  alt: string;
  text: string;
  title: string;
  bgColor: string;
}

export const CardProduct = ({
  src,
  alt,
  text,
  title,
  bgColor,
}: IproductProps) => {
  return (
    <Grid maxW="350px" w="100%" h="450px" bg="white.500" borderRadius="8px">
      <Center w="100%">
        <Image w="85%" borderRadius="8px" src={src} alt={alt} />
      </Center>
      <Grid pl="6">
        <Flex>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <AiFillStar key={index} fill={`${theme.colors.secondary[800]}`} />
          ))}
        </Flex>
        <Text color="grey.700" fontSize="sm">
          {text}
        </Text>
        <Heading color="black.600" fontSize="xl">
          {title}
        </Heading>
      </Grid>
    </Grid>
  );
};
