export const planData = [
  {
    planId: 4,
    planTitle: "Basic",
    planPrice: 39,
    benefitsIncluded: [
      "Biblioteca de Produtos",
      "Precificador de Produto",
      "Gerador de Copy",
      "Fornecedores",
    ],
    benefitsNotIncluded: [
      "Produtos em alta",
      "Esteira de Produtos",
      "Curso de Dropshipping",
      "Gerente de Conta Exclusivo",
    ],
  },
  {
    planId: 5,
    planTitle: "Gold",
    planPrice: 67,
    benefitsIncluded: [
      "Biblioteca de Produtos",
      "Precificador de Produto",
      "Gerador de Copy",
      "Fornecedores",
      "Produtos em alta",
      "Esteira de Produtos",
      "Curso de Dropshipping",
    ],
    benefitsNotIncluded: ["Gerente de Conta Exclusivo"],
  },
  {
    planId: 9,
    planTitle: "Premium",
    planPrice: 149,
    benefitsIncluded: [
      "Biblioteca de Produtos",
      "Precificador de Produto",
      "Gerador de Copy",
      "Fornecedores",
      "Produtos em alta",
      "Esteira de Produtos",
      "Curso de Dropshipping",
      "Gerente de Conta Exclusivo",
    ],
  },
];
