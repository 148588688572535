import { Flex, Grid, Heading, Image, Text } from "@chakra-ui/react";

import { theme } from "../../styles";

interface IsectionProps {
  src: string;
  alt: string;
  text: string;
  title: string;
  step: number;
}

export const SectionData = ({ src, alt, title, text, step }: IsectionProps) => {
  return (
    <Grid
      gap="4"
      p="2rem 1rem"
      bg="grey.400"
      display="flex"
      flexDir="column"
      borderRadius="4px"
      border={`2px solid ${theme.colors.grey[500]}`}
      alignItems={["center", "center", "center", "flex-start"]}
    >
      <Flex alignItems="center" gap="4">
        <Image src={src} alt={alt} w="3rem" h="3rem" />

        <Flex
          w="80px"
          h="45px"
          color="grey.900"
          border={`2px solid ${theme.colors.primary[500]}`}
          fontSize="1.125rem"
          alignItems="center"
          borderRadius="10px"
          justifyContent="center"
        >
          Passo {step}
        </Flex>
      </Flex>
      <Heading
        fontSize="xl"
        fontWeight="700"
        color="black.600"
        textAlign={["center", "center", "center", "left"]}
      >
        {title}
      </Heading>
      <Text
        fontSize="md"
        color="grey.700"
        textAlign={["center", "center", "center", "left"]}
      >
        {text}
      </Text>
    </Grid>
  );
};
