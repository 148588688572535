import detox from "../../assets/images/products/detox.webp";
import corretor from "../../assets/images/products/corretor-de-postura.webp";
import academia from "../../assets/images/products/academia.webp";
import instahair from "../../assets/images/products/instahair.webp";

import { Grid, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { CardProduct } from "./CardProduct";
import { GridContainer } from "../Reusable/GridContainer";

import "./styles.css";

export const ProductsSection = () => {
  return (
    <GridContainer bgColor="rgba(128, 166, 255, 0.07)">
      <Helmet>
        <title>PlayDrop - LandingPage - Seção de Produtos</title>
        <meta name="description" content="Seção de produtos da PlayDrop" />
      </Helmet>
      <Grid
        w="100%"
        m={["0", "0", "0", "0 auto"]}
        pt={["0", "0", "0", "6.5rem"]}
        pb={["0", "0", "0", "6.5rem"]}
        pr={["1rem", "1rem", "1rem", "0"]}
        pl={["1rem", "1rem", "1rem", "0"]}
      >
        <Grid
          w="100%"
          m={["0", "0", "0 auto", "0 auto 2rem auto"]}
          pr={["1rem", "1rem", "1rem", "0"]}
          pl={["1rem", "1rem", "1rem", "0"]}
        >
          <Heading
            fontWeight="700"
            color="black.600"
            textAlign="center"
            mb={["6", "6", "6", "0"]}
            fontSize={["2xl", "2xl", "2xl", "3xl"]}
          >
            Produtos vencedores da
            <Text ml="2" color="primary.500">
              PlayDrop
            </Text>
          </Heading>
        </Grid>

        <div className="container">
          {/* <div className="left" />
          <div className="right" /> */}

          <div className="slider">
            <CardProduct
              src={detox}
              title="Produto Detox"
              alt="Produto Detox"
              bgColor="primary.100"
              text="Faturou mais de 3 milhões em vendas"
            />
            <CardProduct
              src={academia}
              title="Produto HandGrip"
              bgColor="success.100"
              alt="Produto handgrip academia"
              text="Faturou mais de 02 milhões em vendas"
            />
            <CardProduct
              src={corretor}
              bgColor="danger.100"
              title="Produto Corretor Postural"
              alt="Produto Corretor Postural"
              text="Faturou mais de 02 milhões em vendas"
            />
            <CardProduct
              src={instahair}
              bgColor="grey.200"
              title="Produto InstaHair"
              alt="Produto pó compacto instahair"
              text="Faturou mais de 2,5 milhões em vendas"
            />
            <CardProduct
              src={detox}
              title="Produto Detox"
              alt="Produto Detox"
              bgColor="primary.100"
              text="Faturou mais de 3 milhões em vendas"
            />
            <CardProduct
              src={academia}
              title="Produto HandGrip"
              bgColor="success.100"
              alt="Produto handgrip academia"
              text="Faturou mais de 02 milhões em vendas"
            />
            <CardProduct
              src={corretor}
              bgColor="danger.100"
              title="Produto Corretor Postural"
              alt="Produto Corretor Postural"
              text="Faturou mais de 02 milhões em vendas"
            />
            <CardProduct
              src={instahair}
              bgColor="grey.200"
              title="Produto InstaHair"
              alt="Produto pó compacto instahair"
              text="Faturou mais de 2,5 milhões em vendas"
            />
          </div>
        </div>
      </Grid>
    </GridContainer>
  );
};
