import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaArrowDown, FaTimes } from "react-icons/fa";

interface ModalOpenThemeProps {
  isOpen: boolean;
  onClose: () => void;
  themeLink: string;
}

export const ModalOpenTheme = ({
  isOpen,
  onClose,
  themeLink,
}: ModalOpenThemeProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);

      const timer = setTimeout(() => {
        if (linkRef.current) {
          linkRef.current.click();
          onClose();
          setLoading(false);
        }
      }, 2000);

      return () => {
        clearTimeout(timer);
        setLoading(false);
      };
    }
  }, [isOpen, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent w="100%" maxW="28rem" borderRadius="0.625rem">
        <ModalHeader
          p="6"
          w="100%"
          h="3.75rem"
          bg="#3058A5"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="0.625rem 0.625rem 0 0"
        >
          <Flex alignItems="center" gap="0.5rem">
            <Center w="1.5rem" h="1.5rem">
              <FaArrowDown size="1.5rem" color="#fff" />
            </Center>

            <Text ml="2" color="#fff" fontSize="1rem">
              Visualizar Tema
            </Text>
          </Flex>

          <Center
            w="22px"
            h="22px"
            cursor="pointer"
            onClick={() => onClose()}
            borderRadius="50%"
            border="1px solid #fff"
          >
            <FaTimes fill="#fff" size="1rem" />
          </Center>
        </ModalHeader>
        <ModalBody bg="#fff" borderRadius="0 0 0.625rem 0.625rem">
          <Grid
            w="100%"
            p="1rem"
            bg="#fff"
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap="1rem"
          >
            <Heading
              as="h4"
              color="primary.500"
              fontSize="1.5rem"
              fontWeight="500"
              textAlign="center"
            >
              Use a senha abaixo para visualizar o Tema
            </Heading>

            <Box
              w="12.5rem"
              h="3.125rem"
              bg="#fff"
              color="primary.500"
              display="flex"
              fontSize="1.5rem"
              fontWeight="500"
              alignItems="center"
              justifyContent="center"
              boxShadow="0px 8px 12px -1.7px #e6eeff"
              borderRadius="10px"
            >
              SENHA: 1
            </Box>

            <Link
              w="100%"
              h="40px"
              bg="linear-gradient(89.97deg, #3058a5 0.44%, #2964d3 99.97%)"
              rel="noreferrer"
              ref={linkRef}
              href={themeLink}
              color="#fff"
              target="_blank"
              display="flex"
              fontWeight="600"
              alignItems="center"
              borderRadius="6px"
              justifyContent="center"
              _hover={{
                opacity: 0.8,
                transition: "ease-in 0.2s",
              }}
            >
              {loading ? (
                <Spinner
                  size="lg"
                  color="white"
                  speed="0.65s"
                  thickness="4px"
                  emptyColor="gray.200"
                />
              ) : (
                "Ver loja ao vivo"
              )}
            </Link>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
