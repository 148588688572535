import { Header } from "../Header";
import { planData } from "./planData";
import { Grid, Heading, useBreakpointValue } from "@chakra-ui/react";

import { InView } from "react-intersection-observer";
import { CardPlan } from "./CardPlan";

export const PlansSection = () => {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <Grid id="plans" w="100%" bg="grey.400" overflowY="hidden">
      <Header />
      <InView>
        {({ ref, inView }) => (
          <Grid
            w="100%"
            ref={ref}
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <Grid
              w="100%"
              display="flex"
              flexDir="column"
              alignItems="center"
              transition="transform 1s ease"
              transform={
                !isWideVersion
                  ? `translateY(${inView ? "0" : "100%"})`
                  : `translateY(${inView ? "0" : "100px"})`
              }
            >
              <Heading
                as="h1"
                mt="3rem"
                mb="2rem"
                color="black.600"
                textAlign="center"
                fontSize={["3xl", "3xl", "4xl"]}
              >
                O seu primeiro passo
              </Heading>
              <Heading
                as="h3"
                w="100%"
                mb="2rem"
                maxW="500px"
                color="grey.400"
                textAlign="center"
                fontSize={["lg", "lg", "xl"]}
              >
                Escolha um plano e tenha acesso a ferramenta exclusiva
              </Heading>
            </Grid>
            <Grid w="100%" p="1rem" m="0 auto" maxW="80rem">
              <Grid
                gap="10"
                w="100%"
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
              >
                {planData.map((plan) => (
                  <CardPlan
                    key={plan.planId}
                    planId={plan.planId}
                    planTitle={plan.planTitle}
                    planPrice={plan.planPrice}
                    benefitsIncluded={plan.benefitsIncluded}
                    benefitsNotIncluded={plan.benefitsNotIncluded}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </InView>
    </Grid>
  );
};
