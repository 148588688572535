import { Box, Center, Flex, Grid, Heading, Link, Text } from "@chakra-ui/react";
import { theme } from "../../styles";
import { currencyFormat } from "../../utils/currencyFormat";
import { BsCheckLg, BsX } from "react-icons/bs";

interface PlanProps {
  planId: number;
  planTitle: string;
  planPrice: number;
  benefitsIncluded: string[];
  benefitsNotIncluded: string[] | undefined;
}

export const CardPlan = ({
  planId,
  planTitle,
  planPrice,
  benefitsIncluded,
  benefitsNotIncluded,
}: PlanProps) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Grid
      p="8"
      display="flex"
      flexDir="column"
      borderRadius="1rem"
      border="2px solid #F2F2F2"
      bg={planId === 9 ? theme.colors.primary[500] : theme.colors.white[600]}
    >
      {planId === 9 && (
        <Box
          p="2"
          fontSize="xs"
          color="black.600"
          bg="secondary.300"
          borderRadius="7px"
          m="0 auto 1rem auto"
        >
          Mais vendido
        </Box>
      )}
      <Heading
        mb="6"
        fontSize="2xl"
        fontWeight="800"
        textAlign="center"
        color={
          planId === 9 ? theme.colors.grey[500] : theme.colors.primary[500]
        }
      >
        {planTitle}
      </Heading>
      <Grid display="flex" flexDir="column">
        {planId !== 4 && (
          <Text
            color={
              planId === 9 ? theme.colors.grey[500] : theme.colors.black[600]
            }
            fontSize="xs"
            fontWeight="400"
            textAlign="center"
          >
            A partir de:
          </Text>
        )}
        <Flex mb="1.5rem" alignItems="baseline" justifyContent="center">
          <Text
            fontSize="2xl"
            fontWeight="700"
            color={
              planId === 9 ? theme.colors.grey[500] : theme.colors.black[600]
            }
          >
            {currencyFormat(planPrice)}
          </Text>
          <Text
            ml="2"
            fontSize="md"
            color={
              planId === 9 ? theme.colors.grey[500] : theme.colors.black[600]
            }
          >
            / mês
          </Text>
        </Flex>
      </Grid>
      <Grid gap="6">
        {benefitsIncluded.map((benefit) => (
          <Flex gap="4" key={benefit} alignItems="baseline">
            <Center>
              <BsCheckLg
                size="1.2rem"
                fill={
                  planId === 9
                    ? theme.colors.grey[500]
                    : theme.colors.success[600]
                }
              />
            </Center>
            <Text
              fontSize="md"
              color={
                planId === 9 ? theme.colors.grey[500] : theme.colors.black[600]
              }
            >
              {benefit}
            </Text>
          </Flex>
        ))}
        {benefitsNotIncluded?.map((benefit) => (
          <Flex gap="4" key={benefit} alignItems="baseline">
            <Center>
              <BsX
                size="1.2rem"
                fill={
                  planId === 9
                    ? theme.colors.grey[500]
                    : theme.colors.danger[500]
                }
              />
            </Center>
            <Text
              fontSize="md"
              color={
                planId === 9 ? theme.colors.grey[500] : theme.colors.danger[500]
              }
            >
              {benefit}
            </Text>
          </Flex>
        ))}
      </Grid>
      {planId === 9 ? (
        <Link
          w="18rem"
          h="3.32rem"
          display="flex"
          target="_blank"
          rel="noreferrer"
          href="https://pay.kirvano.com/4d360586-2361-4f0b-aa90-3d953249a5e3"
          color="black.600"
          bg="secondary.500"
          borderRadius="4px"
          alignItems="center"
          justifyContent="center"
          m="2rem auto 1rem auto"
          _hover={{
            transition: "ease-in 0.2s",
            color: `${theme.colors.primary[500]}`,
            bgColor: `${theme.colors.secondary[300]}`,
          }}
          onClick={() => {
            fetch("https://playdrop.com.br/cadastro", {
              method: "GET",
              mode: "no-cors",
            });
            // onOpen();
          }}
        >
          Assine o {planTitle}
        </Link>
      ) : planId === 4 ? (
        <Link
          w="18rem"
          h="3.32rem"
          bg="grey.300"
          display="flex"
          target="_blank"
          rel="noreferrer"
          href="https://pay.kirvano.com/4b05567b-e942-4cd4-8522-cb35a54df55b"
          borderRadius="4px"
          color="primary.500"
          alignItems="center"
          justifyContent="center"
          m="2rem auto 1rem auto"
          onClick={() => {
            fetch("https://playdrop.com.br/cadastro", {
              method: "GET",
              mode: "no-cors",
            });
            // onOpen();
          }}
          _hover={{
            transition: "ease-in 0.2s",
            color: `${theme.colors.grey[500]}`,
            bgColor: `${theme.colors.primary[500]}`,
          }}
          border={`2px solid ${theme.colors.primary[500]}`}
        >
          Comece com o {planTitle}
        </Link>
      ) : (
        <Link
          w="18rem"
          h="3.32rem"
          bg="grey.300"
          display="flex"
          target="_blank"
          rel="noreferrer"
          href="https://pay.kirvano.com/b1f4c8a2-d71a-4ff3-929c-1092b966d0ea"
          borderRadius="4px"
          color="primary.500"
          alignItems="center"
          justifyContent="center"
          m="2rem auto 1rem auto"
          onClick={() => {
            fetch("https://playdrop.com.br/cadastro", {
              method: "GET",
              mode: "no-cors",
            });
            // onOpen();
          }}
          _hover={{
            transition: "ease-in 0.2s",
            color: `${theme.colors.grey[500]}`,
            bgColor: `${theme.colors.primary[500]}`,
          }}
          border={`2px solid ${theme.colors.primary[500]}`}
        >
          Comece com o {planTitle}
        </Link>
      )}

      {/* <ModalChoosePlan
        isOpen={isOpen}
        onClose={onClose}
        planId={planId}
        planTitle={planTitle}
        planPrice={planPrice}
        benefitsIncluded={benefitsIncluded}
        benefitsNotIncluded={benefitsNotIncluded}
      /> */}
    </Grid>
  );
};
