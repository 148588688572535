import { theme } from "../../styles";
import { Helmet } from "react-helmet-async";
import { GridContainer } from "../Reusable/GridContainer";
import { Center, Grid, Heading, Image, Text } from "@chakra-ui/react";

import { InView } from "react-intersection-observer";

import ia from "../../assets/images/illustration/ia.jpg";
import temas from "../../assets/images/illustration/temas.jpg";
import produtos from "../../assets/images/illustration/produtos.jpg";
import fornecedores from "../../assets/images/illustration/fornecedores.jpg";
import { Button } from "../Reusable/Button";

import { Link as ScrollLink } from "react-scroll";

export const PlaydropFeatures = () => {
  return (
    <GridContainer bgColor="rgba(128, 166, 255, 0.07)">
      <Helmet>
        <title>PlayDrop - LandingPage - Funcionalidades da PlayDrop</title>
        <meta
          name="description"
          content="Seção de funcionalidades da PlayDrop"
        />
      </Helmet>

      <InView>
        {({ ref, inView }) => (
          <Grid
            w="100%"
            maxW="91rem"
            ref={ref}
            transition="transform 1s ease"
            transform={`translateY(${inView ? "0" : "100px"})`}
            m={["0", "0", "0", "0 auto"]}
            pt={["0", "0", "0", "6.5rem"]}
            pb={["0", "0", "0", "2rem"]}
            pr={["1rem", "1rem", "1rem", "0"]}
            pl={["1rem", "1rem", "1rem", "0"]}
          >
            <Grid
              w="100%"
              m={["0", "0", "0 auto", "0 auto 2rem auto"]}
              pr={["1rem", "1rem", "1rem", "0"]}
              pl={["1rem", "1rem", "1rem", "0"]}
            >
              <Heading
                fontWeight="700"
                color="black.600"
                textAlign="center"
                mb={["6", "6", "6", "0"]}
                fontSize={["2xl", "2xl", "2xl", "3xl"]}
              >
                Funcionalidades da
                <Text ml="2" color="primary.500">
                  PlayDrop
                </Text>
              </Heading>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns={[
                "repeat(auto-fill, minmax(300px, 1fr))",
                "repeat(auto-fill, minmax(400px, 1fr))",
                "repeat(auto-fill, minmax(500px, 1fr))",
              ]}
              gridGap="2rem"
            >
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={produtos}
                    alt="Imagem da tela de produtos da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Pare de perder tempo procurando produto
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    Na aba de Produtos em alta da PlayDrop, você tem acesso a
                    uma seleção dos melhores produtos já validados, com copy
                    pronta, criativos de alta conversão.
                  </Text>
                </Grid>
              </Grid>
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={fornecedores}
                    alt="Imagem da tela de fornecedores da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Fornecedores Nacionais!
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    Sabemos como é frustrante lidar com fornecedores ruins, uma
                    enxurrada de devoluções e reembolsos. Selecionamos a dedo os
                    melhores fornecedores internacional e nacional.
                  </Text>
                </Grid>
              </Grid>
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={temas}
                    alt="Imagem da tela de construtor de lojas da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Sua loja integrada conosco!
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    Você poderá integrar a sua loja Shopify conosco e importar produtos diretamente para ela com um único clique. 
                  </Text>
                </Grid>
              </Grid>
              <Grid
                p="1.5rem"
                bg="white.500"
                borderRadius="1rem"
                boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
              >
                <Center w="100%" h="auto">
                  <Image
                    w="100%"
                    h="100%"
                    src={ia}
                    alt="Imagem da tela de gerador de copy da plataforma PlayDrop"
                    borderRadius="1rem"
                  />
                </Center>
                <Grid p="1rem">
                  <Heading mb="10px" color="primary.500" fontSize="1.5rem">
                    Gerador de Copys Milionárias
                  </Heading>
                  <Text fontSize="1rem" color="grey.700">
                    Gerar copys irresistíveis para produtos, criar nomes
                    criativos e memoráveis para sua loja, definir personas e
                    públicos-alvo com precisão.
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </InView>
      <ScrollLink to="plans" smooth={true} duration={500} offset={-70}>
        <Button
          onClick={() => {
            fetch("https://playdrop.com.br/cadastro", {
              method: "GET",
              mode: "no-cors",
            });
          }}
        >
          COMECE AGORA
        </Button>
      </ScrollLink>
    </GridContainer>
  );
};
