import { Grid } from "@chakra-ui/react";
import { FooterContent } from "./FooterContent";
// import { BuildYourEcommerce } from "./BuildYourEcommerce";

export const Footer = () => {
  return (
    <Grid as="footer">
      {/* <BuildYourEcommerce /> */}
      <FooterContent />
    </Grid>
  );
};
