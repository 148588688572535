export const commonQuestionData = [
  {
    questionId: 1,
    questionTitle: "O que é Dropshipping?",
    questionDescription:
      "O dropshipping é uma forma de venda no qual não é mais necessário ter um estoque físico de produtos para realizar a sua venda. Quando o cliente efetuar uma compra em sua loja, o pedido deve ser repassado ao fornecedor, que fica responsável pelo envio dos produtos ao cliente.",
    moreInfo: [],
  },
  {
    questionId: 2,
    questionTitle: "Como faço para iniciar no Dropshipping?",
    questionDescription:
      "Você deverá escolher um nicho ou um público para quem deseja vender, e focar em produtos para esse público, depois: Criar sua loja, utilizar nossas ferramentas para seleção dos produtos",
    moreInfo: [],
  },
  {
    questionId: 3,
    questionTitle: "Em quanto tempo recebo minha loja?",
    questionDescription:
      "Em 01 minuto! Após a assinatura de um de nossos planos, você irá criar a sua loja, de uma maneira muito simples, escolhendo um dos temas.",
    moreInfo: [],
  },
  {
    questionId: 8,
    questionTitle: "Posso cancelar a qualquer momento?",
    questionDescription:
      "Sim, e para isso disponibilizamos uma garantia de 07 dias, e também um Plano com ótimo custo para que você possa vivenciar o mercado e ver se é isso que realmente deseja",
    moreInfo: [],
  },
];
