import { Helmet } from "react-helmet-async";
import { Grid, Heading, Text } from "@chakra-ui/react";
import { SliderCard } from "./SliderCard";
import { carouselInfo } from "./carouselInfo";

import "../ProductsSection/styles.css";

export const FeedbackSection = () => {
  return (
    <Grid
      pl="6"
      pr="6"
      w="100%"
      as="section"
      bg="white.500"
      pb={["10", "10", "10", "0"]}
      maxH={["auto", "auto", "auto", "100vh"]}
    >
      <Helmet>
        <title>PlayDrop - LandingPage - Seção de feedbacks da plataforma</title>
        <meta
          name="description"
          content="Seção de feedbacks dos temas da plataforma PlayDrop"
        />
      </Helmet>
      <Grid
        w="100%"
        m={["0", "0", "0", "0 auto"]}
        pb={["0", "0", "0", "6.5rem"]}
        pr={["1rem", "1rem", "1rem", "0"]}
        pl={["1rem", "1rem", "1rem", "0"]}
      >
        <Grid
          w="100%"
          maxW="600px"
          m={["0", "0", "0 auto", "2rem auto"]}
          pr={["1rem", "1rem", "1rem", "0"]}
          pl={["1rem", "1rem", "1rem", "0"]}
        >
          <Heading
            fontWeight="700"
            color="black.600"
            textAlign="center"
            mb={["6", "6", "6", "0"]}
            fontSize={["2xl", "2xl", "2xl", "3xl"]}
          >
            Veja o que acharam da
            <Text ml="2" color="primary.500">
              PlayDrop
            </Text>
          </Heading>
        </Grid>

        <div className="container">
          <div className="slider">
            {carouselInfo.map((item) => (
              <SliderCard item={item} />
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
