import { Grid, Heading, Text } from "@chakra-ui/react";

interface IheadingsContentProps {
  title: string;
  text?: string;
  margin_bottom?: string;
}

export const HeadingsContent = ({
  title,
  text,
  margin_bottom = "10",
}: IheadingsContentProps) => {
  return (
    <Grid
      w="100%"
      display="flex"
      flexDir="column"
      gap={text && "8"}
      alignItems="center"
    >
      <Heading
        fontWeight="700"
        color="black.600"
        mb={margin_bottom}
        textAlign="center"
        fontSize={["2xl", "2xl", "2xl", "3xl"]}
      >
        {title}
      </Heading>
      {text && (
        <Text
          fontSize="md"
          color="grey.700"
          textAlign="center"
          mb={margin_bottom}
        >
          {text}
        </Text>
      )}
    </Grid>
  );
};
