import { keyframes } from "@emotion/react";

export const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-500px);
  }
  to{
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const pulse = keyframes`
  0% {
    box-shadow: #ffb300 0 0 0 0;
  }
  75% {
    box-shadow: #ffb30000 0 0 0 16px;
  }
`;

export const pulseButton = keyframes`
  0% {
    box-shadow: #3058A5 0 0 0 0;
  }
  75% {
    box-shadow: #3059A500 0 0 0 16px;
  }
`;
