import { Link } from "@chakra-ui/react";
import { ReactNode } from "react";
import { pulseButton } from "../../keyframes";

interface IbuttonProps {
  children: ReactNode;
  alignSchema?: boolean;
  onClick?: () => void;
}

export const Button = ({
  children,
  alignSchema = false,
  onClick,
}: IbuttonProps) => {
  return (
    <Link
      w="18rem"
      h="3.32rem"
      fontSize="md"
      display="flex"
      target="_blank"
      rel="noreferrer"
      bg="primary.500"
      color="grey.300"
      borderRadius="10px"
      alignItems="center"
      justifyContent="center"
      _hover={{ opacity: 0.7 }}
      transition="ease-in-out 0.2s"
      m={alignSchema ? "1rem 0" : "2rem auto"}
      href="https://www.app.playdrop.com.br/cadastro"
      animation={`${pulseButton} 2000ms infinite`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
