import { Helmet } from "react-helmet-async";
import { CardQuestion } from "./CardQuestion";
import { commonQuestionData } from "./commonQuestionData";
import { Grid, Heading, Text } from "@chakra-ui/react";

interface IcommonProps {
  currentPage?: boolean;
}

export const CommonQuestionsSection = ({ currentPage }: IcommonProps) => {
  return (
    <Grid w="100%" as="section" minH="100vh" bg="grey.50">
      <Helmet>
        <title>PlayDrop - Dropshipping</title>
        <meta
          name="description"
          content="Seção de perguntas frequentes da plataforma PlayDrop"
        />
      </Helmet>
      <Grid
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        m={["0", "0", "0", "0 auto"]}
        pt={currentPage ? "8rem" : "10"}
        w={["100%", "100%", "100%", "75%"]}
      >
        <Heading
          mb="4"
          fontWeight="700"
          color="black.600"
          textAlign="center"
          fontSize={["2xl", "3xl", "4xl"]}
        >
          Principais dúvidas
        </Heading>
        <Text mb="10" color="grey.700" textAlign="center" fontSize="lg">
          Perguntas mais frequentes dos nossos usuários
        </Text>
        <Grid
          p="2"
          gap="4"
          w="100%"
          display="grid"
          justifyItems="center"
          gridTemplateColumns="1fr"
        >
          {commonQuestionData.map((question) => (
            <CardQuestion
              key={question.questionId}
              moreInfo={question.moreInfo}
              questionTitle={question.questionTitle}
              questionDescription={question.questionDescription}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
