import {
  List,
  Drawer,
  Center,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  UnorderedList,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { Link as ScrollLink } from "react-scroll";

import { theme } from "../../styles";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

interface IdrawerMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DrawerMenu = ({ isOpen, onClose }: IdrawerMenuProps) => {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="full">
      <DrawerOverlay />
      <DrawerContent bg="primary.500">
        <DrawerHeader>
          <Center
            w="3rem"
            h="3rem"
            ml="auto"
            as="button"
            onClick={() => onClose()}
          >
            <AiOutlineArrowRight
              size="2.5rem"
              fill={`${theme.colors.grey[500]}`}
            />
          </Center>
        </DrawerHeader>
        <DrawerBody>
          <UnorderedList
            gap="6"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <List color="grey.500" fontSize="1.5rem">
              <Link to="/" onClick={onClose}>
                Funcionalidades
              </Link>
            </List>
            <List onClick={onClose} color="grey.500" cursor="pointer" fontSize="1.5rem">
              <ScrollLink to="plans" smooth={true} duration={500} offset={-70}>
                Planos
              </ScrollLink>
            </List>
            <List color="grey.500" fontSize="1.5rem">
              <ChakraLink
                rel="noreferrer"
                href="https://www.app.playdrop.com.br/cadastro"
                target="_blank"
                onClick={onClose}
              >
                Produtos
              </ChakraLink>
            </List>
            <List w={["100%", "70%"]}>
              <ChakraLink
                h="3rem"
                w="100%"
                display="flex"
                target="_blank"
                rel="noreferrer"
                bg="primary.500"
                color="grey.500"
                fontSize="0.88rem"
                alignItems="center"
                borderRadius="0.3rem"
                justifyContent="center"
                _hover={{ opacity: 0.7 }}
                href="https://app.playdrop.com.br"
                border={`2px solid ${theme.colors.grey[500]}`}
              >
                ENTRAR
              </ChakraLink>
            </List>
            <List w={["100%", "70%"]}>
              <ChakraLink
                h="3rem"
                w="100%"
                display="flex"
                target="_blank"
                rel="noreferrer"
                color="black.500"
                bg="secondary.500"
                fontSize="0.88rem"
                alignItems="center"
                borderRadius="0.3rem"
                justifyContent="center"
                href="https://www.app.playdrop.com.br/cadastro"
                onClick={() => {
                  fetch("https://playdrop.com.br/cadastro", {
                    method: "GET",
                    mode: "no-cors",
                  });
                }}
                _hover={{
                  transition: "ease-in 0.2s",
                  color: `${theme.colors.grey[500]}`,
                  bgColor: `${theme.colors.primary[500]}`,
                  border: `2px solid ${theme.colors.secondary[500]}`,
                }}
              >
                COMEÇAR
              </ChakraLink>
            </List>
          </UnorderedList>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
