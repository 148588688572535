import { extendTheme, theme as ChakraTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: {
      100: "#E4E9FF",
      200: "#A3B5FF",
      250: "#80A6FF",
      300: "#527DFF",
      400: "#2964FF",
      500: "#014EFF",
      550: "#052AAE",
      600: "#0046EB",
      700: "#003ED6",
      800: "#0037C2",
      900: "#0030AD",
    },
    secondary: {
      100: "#FFEAA3",
      200: "#FFDC7A",
      300: "#FFCD52",
      400: "#FCBB29",
      500: "#FFB300",
      600: "#EBA700",
      700: "#DD9C00",
      800: "#CD9000",
      900: "#BD8300",
    },
    success: {
      100: "#DAF4E4",
      200: "#B5E9C9",
      300: "#8FDDAD",
      400: "#6AD292",
      500: "#45C777",
      600: "#39A462",
      700: "#2D814D",
      800: "#215F39",
      900: "#153C24",
    },
    danger: {
      50: "#E2D6DC",
      100: "#FCEAE9",
      200: "#F8D0CE",
      300: "#F2A6A1",
      400: "#EB7B75",
      500: "#E55148",
      600: "#DB2A1F",
      700: "#AE2119",
      800: "#811913",
      900: "#54100C",
    },
    black: { 500: "#17191C", 600: "#0F1114" },
    white: { 500: "#FFFFFF", 600: "#FAFBFF", 700: "#E3EBF6" },
    grey: {
      50: "#F8F8F8",
      100: "#FAFAFA",
      200: "#F9FAFB",
      300: "#F6F7F9",
      400: "#FCFDFD",
      500: "#F1F4F6",
      600: "#F7F7F7",
      650: "#F0F4FF",
      700: "#5D697A",
      800: "#748295",
      900: "#363D47",
    },
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
  },
});
