import {
  Box,
  Flex,
  Grid,
  Text,
  Image,
  Button,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";

import { Playdrop } from "../../components/Logo";
import { useNavigate } from "react-router-dom";

import ImageNotFound from "../../assets/images/illustration/capa-not-found.webp";

export const NotFound = () => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const navigate = useNavigate();
  const refreshPage = () => window.location.reload();

  return (
    <Grid w="100%" minH="100vh">
      <Flex
        w="100%"
        bg="#3058A4"
        alignItems="center"
        pl={["4", "4", "150px"]}
        pr={["4", "4", "150px"]}
        h={["75px", "75px", "90px"]}
        justifyContent="space-between"
      >
        <Playdrop colorLogo="#FCFDFD" heightLogo="46px" widthLogo="176px" />
        <Button
          h="40px"
          bg="#3058A4"
          color="#FCFDFD"
          fontSize="12px"
          borderRadius="4px"
          _hover={{ opacity: 0.7 }}
          border="2px solid #FCFDFD"
          w={["100px", "100px", "108px"]}
          onClick={() => navigate("/", { replace: true })}
        >
          Entrar
        </Button>
      </Flex>
      <Flex
        w="100%"
        bg="#FCFDFD"
        alignItems="center"
        flexDir={["column", "column", "column", "row"]}
        justifyContent={["stretch", "stretch", "stretch", "space-between"]}
      >
        <Grid
          display="flex"
          flexDir="column"
          pl={["0", "0", "0", "150px"]}
          w={["100%", "100%", "100%", "50%"]}
          alignItems={["center", "center", "center", "flex-start"]}
        >
          <Box
            mb="4"
            h="40px"
            pt="3px"
            w="120px"
            color="#014EFF"
            fontWeight="500"
            fontSize="1.25rem"
            textAlign="center"
            borderRadius="36px"
            bg="rgba(1, 78, 255, 0.1)"
            boxShadow="0px 1px 2px rgba(105, 81, 255, 0.05)"
          >
            Error 404
          </Box>
          <Heading
            mb="4"
            as="h1"
            color="#2A3342"
            fontWeight="700"
            lineHeight="60px"
            w={["100%", "100%", "300px"]}
            fontSize={["36px", "40px", "48px"]}
            textAlign={["center", "center", "center", "left"]}
          >
            Página não encontrada
          </Heading>
          <Text
            mb="6"
            color="#556987"
            fontSize="20px"
            lineHeight="30px"
            w={["100%", "100%", "100%", "100%", "450px"]}
            textAlign={["center", "center", "center", "left"]}
          >
            Algo deu errado, a página não foi encontrada ou então essa página
            não existe.
          </Text>
          <Flex
            gap="6"
            alignItems="center"
            flexDir={["column", "column", "column", "column", "row"]}
          >
            <Button
              h="53px"
              bg="#014EFF"
              p="16px 32px"
              color="#FCFDFD"
              fontSize="14px"
              borderRadius="4px"
              _hover={{ opacity: 0.7 }}
              onClick={() => navigate("/", { replace: true })}
            >
              VOLTAR PARA PÁGINA INICIAL
            </Button>
            <Button
              h="53px"
              bg="#FCFDFD"
              color="#014EFF"
              fontSize="14px"
              fontWeight="500"
              borderRadius="4px"
              _hover={{ opacity: 0.7 }}
              border="2px solid #014EFF"
              onClick={() => refreshPage()}
            >
              TENTAR NOVAMENTE
            </Button>
          </Flex>
        </Grid>
        {isWideVersion && (
          <Image
            w="50%"
            h="90.5vh"
            src={ImageNotFound}
            alt="Imagem de capa para tela de Not Found"
          />
        )}
      </Flex>
    </Grid>
  );
};
