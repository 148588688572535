import { theme } from "../../styles";
import { motion } from "framer-motion";
import { useState } from "react";
import { Center, Flex, Grid, Text } from "@chakra-ui/react";
import { FaPlus, FaRegWindowMinimize } from "react-icons/fa";

interface IcardQuestionProps {
  moreInfo: string[];
  questionTitle: string;
  questionDescription: string;
}

export const CardQuestion = ({
  moreInfo,
  questionTitle,
  questionDescription,
}: IcardQuestionProps) => {
  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  return (
    <Grid
      p="6"
      w="100%"
      maxW="48rem"
      bg="white.500"
      cursor="pointer"
      borderRadius="6px"
      border="2px solid #F1F4F6"
      onClick={() => setShowAnswer(!showAnswer)}
    >
      <Flex
        alignItems="center"
        pb={showAnswer ? "4" : "0"}
        justifyContent="space-between"
      >
        <Text fontSize="xl" color="grey.900" fontWeight="500">
          {questionTitle}
        </Text>
        <Center
          w="2rem"
          h="2rem"
          cursor="pointer"
          borderRadius="4px"
          bg={showAnswer ? "primary.500" : "grey.650"}
        >
          {!showAnswer ? (
            <FaPlus size="1.5rem" fill={theme.colors.grey[800]} />
          ) : (
            <FaRegWindowMinimize
              size="1.5rem"
              style={{ paddingBottom: "0.6rem" }}
              fill={
                !showAnswer
                  ? theme.colors.primary[500]
                  : theme.colors.white[500]
              }
            />
          )}
        </Center>
      </Flex>
      {showAnswer && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          transition={{ duration: 0.4 }}
          style={{ paddingTop: "0.8rem", borderTop: "2px solid #5d697a4e" }}
        >
          <Text fontSize="md" textAlign="left" color="grey.900">
            {questionDescription}
          </Text>
          {moreInfo && (
            <Grid gap="4" mt="4">
              {moreInfo.map((info) => (
                <Text fontSize="md" textAlign="left" color="grey.900">
                  - {info}
                </Text>
              ))}
            </Grid>
          )}
        </motion.div>
      )}
    </Grid>
  );
};
