import { Center, Grid, Heading, Image, useDisclosure } from "@chakra-ui/react";
import { theme } from "../../styles";
import { ModalOpenTheme } from "../Reusable/ModalOpenTheme";

interface ItemplateCardProps {
  src: string;
  alt: string;
  title: string;
  href: string;
}

export const TemplateCard = ({ src, alt, title, href }: ItemplateCardProps) => {
  const {
    isOpen: isOpenModalTheme,
    onOpen: onOpenModalTheme,
    onClose: onCloseModalTheme,
  } = useDisclosure();

  return (
    <Grid
      gap="4"
      w="100%"
      bg="white.500"
      display="flex"
      p="2rem 0.5rem"
      flexDir="column"
      alignItems="center"
      position="relative"
      borderRadius="0.8rem"
      _hover={{ transform: "scale(1.1)", transition: "ease-in 0.2s" }}
      boxShadow={`0px 15.1px 11.0753px -2.0137px ${theme.colors.primary[100]}`}
    >
      <Image w="90%" src={src} alt={alt} />
      <Heading fontSize="xl" color="primary.500">
        {title}
      </Heading>

      {/* <Center
        h="50px"
        w="12rem"
        bottom="-2rem"
        bg="primary.300"
        color="white.500"
        cursor="pointer"
        borderRadius="8px"
        position="absolute"
        transition="ease-in 0.2s"
        _hover={{ opacity: 0.7 }}
        onClick={() => onOpenModalTheme()}
      >
        VER TEMA
      </Center> */}

      <ModalOpenTheme
        isOpen={isOpenModalTheme}
        onClose={onCloseModalTheme}
        themeLink={href}
      />
    </Grid>
  );
};
