import {
  Box,
  Flex,
  Center,
  useDisclosure,
  useBreakpointValue,
  Link as LinkChakra,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { theme } from "../../styles";
import { Playdrop } from "../Logo";
import { DrawerMenu } from "./DrawerMenu";
import { AiOutlineMenu } from "react-icons/ai";

import { Link as ScrollLink } from "react-scroll";

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <Flex
      top="0"
      left="0"
      w="100%"
      zIndex="3"
      h="6.3rem"
      as="header"
      position="fixed"
      bg="primary.500"
      alignItems="center"
      justifyContent="space-between"
      p={["1rem", "1.5rem", "1.5rem", "2rem", "2rem 7rem"]}
    >
      <Link to="/">
        <Box>
          <Playdrop
            widthLogo="11rem"
            colorLogo="#FFFFFF"
            heightLogo="2.88rem"
          />
        </Box>
      </Link>
      {!isWideVersion ? (
        <Center
          w="2.5rem"
          h="2.5rem"
          as="button"
          cursor="pointer"
          onClick={() => onOpen()}
        >
          <AiOutlineMenu size="2.5rem" fill={`${theme.colors.grey[500]}`} />
        </Center>
      ) : (
        <Flex
          gap="6"
          as="nav"
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          <Flex as="li" color="grey.500" fontSize="0.85rem">
            <Link to="/">Funcionalidades</Link>
          </Flex>
          <Flex as="li" color="grey.500" cursor="pointer" fontSize="0.85rem">
            <ScrollLink to="plans" smooth={true} duration={500} offset={-70}>
              Planos
            </ScrollLink>
          </Flex>
          <Flex as="li" color="grey.500" fontSize="0.85rem">
            <LinkChakra
              target="_blank"
              rel="noreferrer"
              href="https://www.app.playdrop.com.br/cadastro"
            >
              Produtos
            </LinkChakra>
          </Flex>
          <Flex as="li">
            <LinkChakra
              h="3rem"
              w="6.75rem"
              display="flex"
              target="_blank"
              rel="noreferrer"
              bg="primary.500"
              color="grey.500"
              fontSize="0.88rem"
              alignItems="center"
              borderRadius="0.3rem"
              justifyContent="center"
              _hover={{ opacity: 0.7 }}
              href="https://www.app.playdrop.com.br"
              border={`2px solid ${theme.colors.grey[500]}`}
            >
              ENTRAR
            </LinkChakra>
          </Flex>
          <Flex as="li">
            <ScrollLink to="plans" smooth={true} duration={500} offset={-70}>
              <LinkChakra
                h="3rem"
                w="9.625rem"
                display="flex"
                color="black.500"
                bg="secondary.500"
                fontSize="0.88rem"
                alignItems="center"
                borderRadius="0.3rem"
                justifyContent="center"
                onClick={() => {
                  fetch("https://playdrop.com.br/cadastro", {
                    method: "GET",
                    mode: "no-cors",
                  });
                }}
                _hover={{
                  transition: "ease-in 0.2s",
                  color: `${theme.colors.grey[500]}`,
                  bgColor: `${theme.colors.primary[500]}`,
                  border: `2px solid ${theme.colors.secondary[500]}`,
                }}
              >
                COMEÇAR
              </LinkChakra>
            </ScrollLink>
          </Flex>
        </Flex>
      )}
      <DrawerMenu isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};
