import { theme } from "../../styles";
import { ReactNode } from "react";
import { Center, Flex, Text } from "@chakra-ui/react";

interface IsocialProps {
  children: ReactNode;
  hrefName: string;
  socialMediaName: string;
}

export const SocialMediaLink = ({
  children,
  hrefName,
  socialMediaName,
}: IsocialProps) => {
  return (
    <Flex
      as="a"
      display="flex"
      href={hrefName}
      target="_blank"
      rel="noreferrer"
      cursor="pointer"
      alignItems="center"
    >
      <Center
        w="2rem"
        h="2rem"
        bg="transparent"
        borderRadius="0.625rem"
        border={`3px solid ${theme.colors.white[700]}`}
      >
        {children}
      </Center>
      <Text ml="2" color="white.700">
        {socialMediaName}
      </Text>
    </Flex>
  );
};
