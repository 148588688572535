import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Helmet } from "react-helmet-async";
import { Center, Grid, Link } from "@chakra-ui/react";
import { InicialSection } from "../../components/InicialSection";
import { ProductsSection } from "../../components/ProductsSection";
import { FeedbackSection } from "../../components/FeedbackSection";
// import { ServicesSection } from "../../components/ServicesSection";
import { TemplatesSection } from "../../components/TemplatesSection";
import { ECommerceSection } from "../../components/ECommerceSection";
import { DiferentialsSection } from "../../components/DiferentialsSection";
import { CommonQuestionsSection } from "../../components/CommonQuestionsSection";

import { PlaydropFeatures } from "../../components/PlaydropFeatures";
import { PlansSection } from "../../components/PlansSection";
import { FaWhatsapp } from "react-icons/fa";

export const LandingPage = () => {
  return (
    <Grid w="100%" minH="100vh" as="main" position="relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PlayDrop LandingPage</title>
        <meta
          name="description"
          content="A PlayDrop é uma Startup de Dropshipping que atua com foco na implementação de um E-commerce tendo foco naquele dropshipper que está iniciando no mercado"
        />
      </Helmet>
      <Header />
      <InicialSection />

      <ProductsSection />

      <ECommerceSection />

      <PlaydropFeatures />

      <TemplatesSection />

      <PlansSection />

      <FeedbackSection />

      <DiferentialsSection />

      {/* <CreateOnlineStoreSection /> */}

      {/* <ArtificialIntelligenceSection /> */}

      <CommonQuestionsSection />

      <Footer />

      <Link
        href="https://wa.me/+5511952177459?text=Ol%C3%A1,%20preciso%20de%20uma%20ajuda"
        target="_blank"
        rel="noreferrer"
        position="fixed"
        bottom="2rem"
        right="1.8rem"
      >
        <Center cursor="pointer" p="0.3rem" bg="#014EFF" borderRadius="50%">
          <FaWhatsapp color="#fff" size="2.8rem" />
        </Center>
      </Link>
    </Grid>
  );
};
