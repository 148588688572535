import { theme } from "../styles";
import { ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { HelmetProvider } from "react-helmet-async";

interface IproviderProps {
  children: ReactNode;
}

export const Providers = ({ children }: IproviderProps) => {
  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </HelmetProvider>
  );
};
