import { pulse } from "../../keyframes";
import { InView } from "react-intersection-observer";

import {
  Box,
  Grid,
  Link,
  Text,
  Flex,
  Heading,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";

import { Link as ScrollLink } from "react-scroll";

import iconOne from "../../assets/images/illustration/LP-ALTO.svg";
import iconTwo from "../../assets/images/illustration/LP-BAIXO.svg";

const vimeoUrl =
  "https://player.vimeo.com/video/1024097439?title=0&byline=0&portrait=0&badge=0&autopause=0&autoplay=1&loop=1&muted=0&controls=1";

export const InicialSection = () => {
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <Grid
      w="100%"
      mt="6.3rem"
      as="section"
      bg="primary.500"
      overflowX="hidden"
      position="relative"
    >
      <Grid w="100%" h="100%">
        <Image
          src={iconOne}
          alt="Ícone de ilustração"
          position="absolute"
          top="0"
          left="0"
        />
      </Grid>
      <InView>
        {({ ref, inView }) => (
          <Flex
            w="100%"
            p="1rem"
            ref={ref}
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            transition="transform 1s ease"
            transform={
              isWideVersion
                ? `translateX(${inView ? "0" : "-100%"})`
                : `translateX(${inView ? "0" : "-100px"})`
            }
          >
            <Grid
              w="100%"
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                mb="6"
                h="2.3rem"
                mt="1rem"
                w="16.38rem"
                fontSize="md"
                bg="primary.300"
                color="grey.400"
                p="0.3rem 1.25rem"
                textAlign="center"
                borderRadius="0.25rem"
              >
                É hora de mudar de vida
              </Box>

              <Heading
                as="h1"
                w="100%"
                maxW="50rem"
                color="grey.400"
                fontSize={["3xl", "4xl", "5xl"]}
                fontWeight="700"
                textAlign="center"
              >
                Descubra produtos validados e tenha uma loja lucrativa em poucos
                cliques
              </Heading>

              <Text
                w="100%"
                mt="6"
                mb="2"
                maxW="50.4rem"
                color="grey.400"
                fontSize="lg"
                textAlign="center"
              >
                Assista ao pequeno vídeo abaixo e descubra como encontrar os
                produtos validados
              </Text>

              <Image
                src={iconTwo}
                alt="Ícone de ilustração"
                position="absolute"
                bottom={["4rem", "8rem", "15rem"]}
                left="0"
              />

              <Grid
                w={["350px", "400px", "500px", "850px"]}
                h={["300px", "300px", "500px"]}
              >
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  title="vimeo-player"
                  src={vimeoUrl}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>

            <ScrollLink to="plans" smooth={true} duration={500} offset={-70}>
              <Link
                mt="8"
                mb="4"
                h="3.5rem"
                w="20rem"
                fontSize="md"
                fontWeight="500"
                display="flex"
                color="black.500"
                bg="secondary.500"
                alignItems="center"
                borderRadius="1rem"
                justifyContent="center"
                _hover={{ opacity: 0.7 }}
                transition="ease-in-out 0.2s"
                animation={`${pulse} 2000ms infinite`}
                onClick={() => {
                  fetch("https://playdrop.com.br/cadastro", {
                    method: "GET",
                    mode: "no-cors",
                  });
                }}
              >
                COMEÇAR AGORA
              </Link>
            </ScrollLink>
          </Flex>
        )}
      </InView>
    </Grid>
  );
};
