import { Flex } from "@chakra-ui/react";
import { theme } from "../../styles";
import { AiFillStar } from "react-icons/ai";

import "./Slider.css";

interface IimageProp {
  src: string;
  alt: string;
}

interface IslideCardProps {
  title: string;
  subtitle: string;
  text: string;
  image: IimageProp;
}

interface IslideProp {
  item: IslideCardProps;
}

export const SliderCard = ({ item }: IslideProp) => {
  return (
    <div className="slider-card">
      <div className="slider-card__content">
        <img src={item.image.src} alt={item.image.alt} />
        <div className="slider-card__title">
          <h3>{item.title}</h3>
          <p>{item.subtitle}</p>
          <Flex mt="2">
            {[1, 2, 3, 4, 5].map((_, index) => (
              <AiFillStar key={index} fill={`${theme.colors.secondary[800]}`} />
            ))}
          </Flex>
        </div>
      </div>
      <div className="slider-card__text">{item.text}</div>
    </div>
  );
};
